exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../../../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mentoring-js": () => import("./../../../src/pages/mentoring.js" /* webpackChunkName: "component---src-pages-mentoring-js" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-en-dark-side-dark-side-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/en/dark-side/dark-side.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-en-dark-side-dark-side-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-en-display-stand-upgrade-display-stand-upgrade-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/en/display-stand-upgrade/display-stand-upgrade.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-en-display-stand-upgrade-display-stand-upgrade-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-en-led-matrix-led-matrix-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/en/led-matrix/led-matrix.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-en-led-matrix-led-matrix-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-en-load-testing-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/en/load-testing.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-en-load-testing-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-en-my-experience-with-react-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/en/my-experience-with-react.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-en-my-experience-with-react-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-en-porting-js-to-ts-with-chatgpt-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/en/porting-js-to-ts-with-chatgpt.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-en-porting-js-to-ts-with-chatgpt-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-pl-dark-side-dark-side-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/pl/dark-side/dark-side.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-pl-dark-side-dark-side-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-pl-display-stand-upgrade-display-stand-upgrade-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/pl/display-stand-upgrade/display-stand-upgrade.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-pl-display-stand-upgrade-display-stand-upgrade-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-pl-led-matrix-led-matrix-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/pl/led-matrix/led-matrix.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-pl-led-matrix-led-matrix-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-pl-load-testing-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/pl/load-testing.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-pl-load-testing-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-pl-my-experience-with-react-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/pl/my-experience-with-react.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-pl-my-experience-with-react-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-blog-pl-porting-js-to-ts-with-chatgpt-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/blog/pl/porting-js-to-ts-with-chatgpt.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-blog-pl-porting-js-to-ts-with-chatgpt-mdx" */),
  "component---src-templates-course-js-content-file-path-src-courses-en-advanced-web-testing-tools-mdx": () => import("./../../../src/templates/course.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/courses/en/advanced-web-testing-tools.mdx" /* webpackChunkName: "component---src-templates-course-js-content-file-path-src-courses-en-advanced-web-testing-tools-mdx" */),
  "component---src-templates-course-js-content-file-path-src-courses-en-backed-javascript-mdx": () => import("./../../../src/templates/course.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/courses/en/backed-javascript.mdx" /* webpackChunkName: "component---src-templates-course-js-content-file-path-src-courses-en-backed-javascript-mdx" */),
  "component---src-templates-course-js-content-file-path-src-courses-en-selected-network-aspects-mdx": () => import("./../../../src/templates/course.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/courses/en/selected-network-aspects.mdx" /* webpackChunkName: "component---src-templates-course-js-content-file-path-src-courses-en-selected-network-aspects-mdx" */),
  "component---src-templates-course-js-content-file-path-src-courses-pl-advanced-web-testing-tools-mdx": () => import("./../../../src/templates/course.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/courses/pl/advanced-web-testing-tools.mdx" /* webpackChunkName: "component---src-templates-course-js-content-file-path-src-courses-pl-advanced-web-testing-tools-mdx" */),
  "component---src-templates-course-js-content-file-path-src-courses-pl-backend-javascript-mdx": () => import("./../../../src/templates/course.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/courses/pl/backend-javascript.mdx" /* webpackChunkName: "component---src-templates-course-js-content-file-path-src-courses-pl-backend-javascript-mdx" */),
  "component---src-templates-course-js-content-file-path-src-courses-pl-selected-network-aspects-mdx": () => import("./../../../src/templates/course.js?__contentFilePath=/opt/build/repo/korczak-xyz/src/courses/pl/selected-network-aspects.mdx" /* webpackChunkName: "component---src-templates-course-js-content-file-path-src-courses-pl-selected-network-aspects-mdx" */)
}

